<template>
  <v-form v-if="item" autocomplete="off">
    <v-row>
        <v-col md=7>

          <v-text-field
            id="firstNameInput"
            name="firstName"
            label="Nome"
            v-model="item.first_name"
          ></v-text-field>

          <v-text-field
            id="lastNameInput"
            name="lastName"
            label="Sobrenome"
            v-model="item.last_name"
          ></v-text-field>

          <v-text-field
            id="emailInput"
            name="email"
            label="Email"
            v-model="item.email"
          ></v-text-field>
          
          <v-text-field
            id="phoneInput"
            name="phone"
            label="Telefone"
            v-model="item.phone"
            return-masked-value
            v-mask="'(##) ####-#####'"
          ></v-text-field>

        </v-col>

        <v-col>

        </v-col>

    </v-row>

    <v-row>

        <back-button
          view="small"
        ></back-button>

        <save-button view="big" @save="submit()">
        </save-button>
    </v-row>


  </v-form>
  
</template>

<script>
    import BackButton from '../../components/BackButton.vue'
  import SaveButton from '../../components/SaveButton.vue'
  const {EventBus} = require('../../addons/event-bus.js')

export default {
    name: 'UserForm',
    components: {
      BackButton,
      SaveButton
    },
    props: ['item'],
    methods: {
      submit () {
        return EventBus.$emit('profile-submitted')
      }
    }
  }
</script>
