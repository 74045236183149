<template>
 <v-card
    v-if="item"
    class="mx-auto"
    tile
  >
      <v-row>
        <v-col class="">
                <br>
                <p class="title">{{item.first_name}} {{item.last_name}}</p>
                <p>{{item.email}}</p>
                <p>{{item.phone}}</p>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        id="editProfile"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="default"
                        v-bind="attrs"
                        v-on="on"
                        link :to="'/profile/edit'"
                      >
                        <v-icon dark>
                          mdi-pencil
                        </v-icon>
                      </v-btn>
                  </template>
                  <span>Editar</span>
                </v-tooltip>

        </v-col>
        <v-col>
          <v-timeline dense>
            <v-timeline-item
              v-for="event in item.timeline"
              v-bind:key="event.refcode"
              :color="dictionary[event.value] && dictionary[event.value].color ? dictionary[event.value].color : 'blue'"
              :icon="dictionary[event.value] && dictionary[event.value].icon ? dictionary[event.value].icon : ''"
            >
              <p>{{event.description}}<br>
              <small>{{event.time}}</small>
              </p>
            </v-timeline-item>
          </v-timeline>        
        </v-col>

      </v-row>
  </v-card>        
</template>
<script>
    export default {
      name: 'ProfileDetail',
      props: ['item'],
      computed: {
        dictionary: function () {
          return this.$store.getters.dictionary
        }
      }
    }
</script>
