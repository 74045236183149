<template>
  <v-form v-if="item">
    <v-row>
        <v-col md=7>

          <v-text-field
            id="passwordInput"
            type="password"
            name="password"
            label="Senha"
            v-model="item.password"
          ></v-text-field>
          
          <v-text-field
            id="confirmationInput"
            type="confirmation"
            name="confirmation"
            label="Confirmação"
            v-model="item.confirmation"
          ></v-text-field>
          
        </v-col>

        <v-col>

        </v-col>

    </v-row>

    <v-row>

        <back-button
          view="small"
        ></back-button>

        <save-button view="big" @save="submit()">
        </save-button>
    </v-row>


  </v-form>
  
</template>

<script>
    import BackButton from '../../components/BackButton.vue'
  import SaveButton from '../../components/SaveButton.vue'
  const {EventBus} = require('../../addons/event-bus.js')

export default {
    name: 'PasswordResetForm',
    components: {
      BackButton,
      SaveButton
    },
    props: ['item'],
    methods: {
      submit () {
        return EventBus.$emit('password-reset-submitted')
      }
    }
  }
</script>
