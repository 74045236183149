<template>
<v-container fluid>
  <v-row>
    <v-col
      md=4
      sm=12
      xs=12
    >
    <v-card
        class="mx-auto"
      >
        <v-img
          alt=""
          class="shrink mr-2"
          contain
          :src="'/static/illustrations/' + image + '.svg'"
          width="100%"
          icon
        />
        <v-card-title>
            <h1 class='title'>Redefinir Senha</h1>
        </v-card-title>
    </v-card>
  </v-col>

    <v-col
    >
      <div v-if="!$store.state.loading">
        <password-reset-form :item="item"></password-reset-form>
      </div>
    </v-col>
  </v-row>
</v-container>
</template>
<script>
import PasswordResetForm from '../components/PasswordResetForm.vue'
  const {EventBus} = require('../../addons/event-bus.js')

export default {
    name: 'ResetPassword',
    components: {
        PasswordResetForm
    },
    data() {
        return {
            image: '',
            item: {
              password: "",
              confirmation: ""
            },
        }
    },
    mounted() {
      EventBus.$on('password-reset-submitted', this.submit)
    },
    beforeDestroy() {
      EventBus.$off('vsubmitted');
    },
    methods: {
      submit() {
        if (this.item.password !== this.item.confirmation) {
          EventBus.$emit('notification', {message: 'Senhas não são iguais', status: 'error'})
          return
        }
        const password = this.item.password
        const p1 = this.$store.dispatch('resetPassword', {password})
        p1.then(r => {
            this.$store.commit('setReady')
            if (r.status < 300) {
                this.$router.push({path: '/profile'})
            }
            return r
        })
      }
    },
}
</script>