<template>
<v-container fluid>
  <v-row>
    <div>
      <profile-detail :item='item'></profile-detail>
    </div>
  </v-row>
</v-container>
</template>

<script>
  import ProfileDetail from '../components/ProfileDetail.vue'

  export default {
    name: 'ViewProfile',
    components: {
      ProfileDetail
    },
    data() {
      return {
        'image': 'Finance'
      }
    },
    computed: {
      raw: function() {
        return this.$store.state.entities.user.profile
      },
      item: function() {
        return this.$store.state.entities.user.profile
      }
    },
    mounted() {
      const load = () => {
        const p1 = this.$store.dispatch('getProfile')
        Promise.all([p1]).then(r => {
          this.$store.commit('setReady')
          return r
        })
      }
      load()
    },
  }
</script>