<template>
<v-container fluid>
  <v-row>
    <v-col
      md=4
      sm=12
      xs=12
    >
    <v-card
        class="mx-auto"
      >
        <v-img
          alt=""
          class="shrink mr-2"
          contain
          :src="'/static/illustrations/' + image + '.svg'"
          width="100%"
          icon
        />
        <v-card-title>
            <h1 class='title'>Editar Perfil</h1>
            <br><br>
            <v-btn class="primary--text" link to="/profile/password/reset">Redefinir Senha</v-btn>
        </v-card-title>
    </v-card>
  </v-col>

    <v-col
    >
      <div v-if="!$store.state.loading">
        <profile-form :item="item"></profile-form>

      </div>
    </v-col>
  </v-row>
</v-container>
</template>
<script>
import ProfileForm from '../components/ProfileForm.vue'
const {processProfile, profileSchema} = require('../descriptors.js')
const {EventBus} = require('../../addons/event-bus.js')

export default {
    name: 'EditProfile',
    components: {
        ProfileForm
    },
    data() {
      return {
        'image': 'Headhunter'
      }
    },
    computed: {
      raw: function() {
        return this.$store.getters.profile
      },
      item: function() {
        return this.$store.getters.profile
      },
    },
    mounted() {
      const submit = () => {
        const item = processProfile(this.item)
        profileSchema.validate(item)
          .then((valid) => {
            const p1 = this.$store.dispatch('updateProfile', item)
            p1.then(r => {
              this.$store.commit('setReady')
              return r
            })
            return valid
          })
          .catch((err) => {
            EventBus.$emit('notification', err)
            return err
          })
      }

      const load = () => {
        const p1 = this.$store.dispatch('getProfile')
        return p1.then((values) => {
          this.$store.commit('setReady')
          return values
        });
      }

      EventBus.$on('profile-submitted', submit)
      load()
    },
    beforeDestroy() {
      EventBus.$off('profile-submitted');
    },
}
</script>